<template>
    <div>
        <van-cell-group title="物料" inset>
            <picker label="翻包物料："
                    placeholder="请选择物料"
                    @onConfirm="materialConfirm"
                    displayField="name"
                    searchParam="code"
                    valueField="id"
                    :required=true
                    v-model:value="this.data['material.id']"
                    :defConfig="{
                    portName:'fr.information.MaterialLibrary.getMaterialLibrarys'
                    }"
                    :rules="[{ required: true, message: '请选择物料' }]"
                    :canSearch=true
                    ref="materialLibrary">
            </picker>

            <picker v-if="this.data['material.id']!==''"
                    label="选择供应商："
                    placeholder="请选择供应商"
                    @onConfirm="supplierConfirm"
                    displayField="supplier.cnName"
                    searchParam="supplier.code"
                    valueField="supplier.id"
                    :required=true
                    v-model:value="this.data['supplier.id']"
                    :defConfig="{
                    portName:'fr.information.MaterialLibrary.getMaterialLibrarys',
                    data:{'code':this.data['materialCode']}
                    }"
                    :rules="[{ required: true, message: '供应商'}]"
                    ref="supplier">
                >
            </picker>
        </van-cell-group>

        <van-cell-group title="包装" inset>
            <Picker
                    name="packID"
                    label="选择生成的包装"
                    placeholder="请选择包装"
                    displayField="pack.name"
                    valueField="pack.id"
                    :required=true
                    v-model:value="this.data['pack.id']"
                    :defConfig="{
                    portName:'fr.information.MaterialPackRel.getMaterialPackRels',
                    data:{'material.id':this.data['material.id']}
                    }"
                    :rules="[{ required: true, message: '请选择包装' }]"
                    :canSearch=true
                    @onConfirm="packConfirm"
                    ref="pack"
            />
            <van-field
                    v-model="generatePackQty"
                    name="generatePackQty"
                    label="生成新包装数量"
                    required
                    :rules="[{ required: true, message: '请填写数量' }]"
            />
        </van-cell-group>

        <div style="margin: 4.26667vw;">
            <van-button round block type="primary" @click="generatePack">生成新包装</van-button>
        </div>
    </div>

</template>

<script>
    import Picker from "../../components/Picker";
    import {Toast} from "vant";

    export default {
        name: "pdaRummage",
        components: {Picker},
        data() {
            return {
                generatePackQty: '',
                materialPackRelID: '',
                data: {
                    'material.id': '',
                    'pack.id': '',
                    'supplier.id': '',
                    'materialCode': ''
                }
            };
        },
        created(){
          if (!this.$sapi.isLogin()) {
            this.$sapi.handleTokenError();
            return;
          }
        },
        methods: {
            materialConfirm: function (value) {
                this.data['material.id'] = value.id;
                this.data['materialCode'] = value['code'];
                this.data['supplier.id'] = '';
                this.data['pack.id'] = '';
            },
            packConfirm: function (value) {
                this.materialPackRelID = value.id;
                this.data['pack.id'] = value['pack.id'];
            },
            generatePack: function () {
                if (this.data['material.id'] === '') {
                    Toast('请选择物料');
                    return;
                }
                if (this.data['supplier.id'] === '') {
                    Toast('请选择供应商');
                    return;
                }
                if (this.data['pack.id'] === '') {
                    Toast('请选择包装');
                    return;
                }
                if (this.generatePackQty === '') {
                    Toast('请输入包装数量')
                    return;
                }
                if (this.generatePackQty < 0) {
                    Toast('包装数量不能小于0');
                    return;
                }

                var me = this;
                var option = {
                    portName: 'fr.warehouse.WareHouseMaterial.addSeparatePack',
                    data: {'materialPackRelID': this.materialPackRelID, 'qty': this.generatePackQty},
                    needLoading: true,
                    successCallback: function (data) {
                        let packIdList = data.data.packIdList;
                        me.$router.push({path: 'commitRummage',query: {packIdList: packIdList}})
                    }
                };
                this.$sapi.callPort(option);
            },
            supplierConfirm(value) {
                this.data['supplier.id'] = value['supplier.id'];
            },
        }
    }
</script>

<style scoped>

</style>
